import React, { useState } from 'react';
import { Button, useListContext, useNotify, useUnselectAll, useDataProvider, useRefresh } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Delete } from '@mui/icons-material';
import MyLoading from '../components/MyLoading';
import { useTranslate  } from '../common/language';


const BulkDeleteButton = ({ resource_name, ...props}) => {
    const { selectedIds, resource, setSelectedIds } = useListContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    const translate = useTranslate(); 

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        setLoading(true);

        const deletePromises = selectedIds.map(id =>
            dataProvider.delete(resource_name, { id })
        );

        const results = await Promise.allSettled(deletePromises);

        const errors = results
            .filter(result => result.status === 'rejected')
            .map((result, index) => ({
                id: selectedIds[index],
                message: result.reason.message,
            }));

        const successCount = results.filter(result => result.status === 'fulfilled').length;

        if (errors.length > 0) {
            notify(translate('users.delete_all_ko') + ` ${errors.map(e => '\n' + e.id + ' -> ' + e.message ).join('\n')}`, 'error');
        } else {
            notify(selectedIds.length + ' ' + translate('users.delete_all_ok') , 'success');
        }

        unselectAll(resource_name);
        refresh();

        handleClose();
        setLoading(false);
    };

    if (loading) return <MyLoading />;
    
    return (
        <>
            <Button label="Esborrar" onClick={handleClickOpen} className="red_button" >
                <Delete />
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {translate('users.delete_all_confirm_title').replace('%num_registers%',selectedIds.length)}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate('users.delete_all_confirm_description')}
                        <br /><br/>{translate('users.delete_all_confirm_description_ask')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} label={translate('ra.action.cancel')} />
                    <Button onClick={handleDelete} label={translate('users.delete_all')} className="red_button" >
                        <Delete />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BulkDeleteButton;
